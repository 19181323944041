@import "../node_modules/bootstrap/scss/functions";
$primary: #0156a3;
$info: #05b3cb;
$warning: #ff8a47;
@import "../node_modules/bootstrap/scss/bootstrap";

:root {
  --dark: rgb(42, 48, 66);
}

.page {
  background-color: #eef2f7;
  padding: 20px;

  &__public {
    min-height: 100vh;
  }
}

body[data-layout="horizontal"] .page-content {
  margin-top: 0px;
  padding: calc(55px + 16px) 0 0 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #ffffff;
  transition: all 0.3s;
}

// .p-dialog .p-dialog-header {
//   height: 60px;
// }

// optional, to change the Blue Bootstrap color in all buttons
.p-button,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: rgb(72, 140, 203);
}
