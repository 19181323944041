.table-container {
  width: 100%;
  margin-top: 2rem;
}

.p-card {
  width: 1200px;
  max-width: 1400px;
}

.p-card-title	{
  color: var(--surface-600);
}

.p-datatable {
  border: solid 1px lightgray;
}

.p-datatable > .p-datatable-theader {
  background-color: blue;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background-color: rgba(0, 109, 252, 0.03) ;
}

.p-timeline-event {
  min-height: 50px;
}

.p-timeline-event-opposite {
  flex: 0
}
