.dashboard-card-list {
    width: 1200px;
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.grid {
        justify-content: center;
        align-items: center;
    }
}