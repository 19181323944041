.table-container {
  width: 100%;
  margin-top: 2rem;
}

.p-card {
  width: 1200px;
  max-width: 1400px;
}

.p-card-title	{
  color: var(--surface-600);
}

.p-datatable {
  border: solid 1px lightgray;
}

.p-datatable > .p-datatable-theader {
  background-color: blue;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background-color: rgba(0, 109, 252, 0.03) ;
}

.highlighted {
  animation: highlight 3s ease-in-out forwards;
}

$highlight-color1: #f2f2d4;
$highlight-color2: #fcfcda;
@keyframes highlight {
  0% {
    background-color: white;
  }
  25% {
    background-color: $highlight-color1;
  }
  50% {
    background-color: white;
  }
  75% {
    background-color: $highlight-color1;
  }
  100% {
    background-color: $highlight-color2;
  }
}