.mapContainerStyle {
  width: 100vw;
  height: calc(100vh - 130px);
}

.autoCompleteContainer {
  left: 50%;
  position: absolute;
  top: 10px;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  align-items: center;
}

.mapContainerStyle__autocompleteInput {
  width: 380px;
}

.mapContainerStyle__addRequest {
  background: #08b3cb;
  border: none;
  box-shadow: -2px -1px 3px 0px #7897a1;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  right: -38px;
  rotate: -90deg;
  top: 50%;
  transition: all 500ms ease;
  // transform: translateY(100%);
}

.mapContainerStyle__addRequest:hover {
  // background-color: #08b3cb !important;
  background: #07a0b4 !important;
  // box-shadow: -6px -3px 5px 0px #7897a1;
}
